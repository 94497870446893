import * as React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import GridProductsLayout from "../../components/layout/grid-products"
import { Seo } from "../../components/helmet/seo"

import {PageTitle} from '../../styles/pages/collectionPage.style'
// import slugify from "@sindresorhus/slugify"
// import { MoreButton } from "../../components/more-button"
import layoutJSON from '../../utils/layout.config.json'


export default function Products({
  data: { products },
  pageContext: { collection },
}) {
  const ldata = layoutJSON.collectionLayout[products.title] ? layoutJSON.collectionLayout[products.title] : {layout: "row", rowCount: {"sm": "1", "md": "5", "lg": "8"}}

  return (
    <Container fluid>
      <Row className="px-2 px-md-3 mx-auto">
        <Col>
          <Seo title={`${collection} products`} />
          <div className="mt-2 mb-5">
            <PageTitle >{products.title}</PageTitle>
            <small>Collections</small>
          </div>
        </Col>
      </Row>
      <GridProductsLayout row={ldata.rowCount.md} gridType={ldata.layout} products={products.products} />
      {/* {products.pageInfo.hasNextPage && (
        <MoreButton to={`/search?v=${slugify(collection)}#more`}>
          More Products
        </MoreButton>
      )} */}
    </Container>
  )
}

export const query = graphql`
  query($handle: String!) {
    products: shopifyCollection(handle: { eq: $handle }) {
      title
      ...ProductCardForCollection
    }
  }
`
